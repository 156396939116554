/* SlideInOverlay.css */

/* Overlay container */
.overlay {
    position: fixed;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Transparent grey overlay */
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0; /* Start with opacity 0, so it's hidden */
    pointer-events: none; /* Clicks will pass through the overlay when it's hidden */
    transform: translatey(100%); /* Start outside the screen on the right */
    transition: opacity 0.3s, transform 0.3s; /* Transition animations for opacity and transform */
  }

  .overlay.open {
    opacity: 1; /* Show the overlay when open */
    pointer-events: auto; /* Allow clicks on the overlay when open */
    transform: translateY(0); /* Slide the overlay in from the right */
  }

/* Content inside the overlay */
.overlay .content {
    padding: 20px;
    background-color: white;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}

.overlay:not(.open) {
    transform: translateY(100%);
    /* Slide the overlay out to the right */
}