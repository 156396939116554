body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* overflow: scroll; */
  touch-action: manipulation;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.david {
  font-family: 'David Libre', serif;
}

.fill {
  text-align: justify;
}

.fill::after {
  content: "";
  display: inline-block;
  width: 100%;
}

/* ol { counter-reset: item } */
/* li{ display: block } */
/* li:before { content: counters(item, ".") " "; counter-increment: item } */
li {
  list-style-position: inside;
}

.fixed-size {
  font-size: 14px;
}

/* input[type=text] {
  border: none;
  border-bottom: 2px solid black;
} */

.input-field {
  width: 150px;
  font-size: 12px;
  padding: 4px;
}

@media (max-width: 768px) {
  .input-field {
    width: 50px;
    height: 14px;
    font-size: 8px;
    padding: 2px;
  }
}

.text-fields{
  z-index: 1;
}

.box1 {
  z-index: 3;
}

.box2 {
  z-index: 2;
}

.box3 {
  z-index: 1;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Grey with 50% opacity */
  z-index: 5; /* Adjust z-index as needed to make sure it appears above other elements */
}

.loading-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(200, 200, 200, 0.5); /* Light grey color with transparency (adjust the alpha value as needed) */
  z-index: 9; /* Set a high z-index value to place the background below the centered object */
}

.loading {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10; /* Set a high z-index value to ensure the object is on top of everything */
}

.centered-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f2f2f2;
}

.hidden{
  display: none;
}